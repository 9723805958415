import { createApp } from 'vue'
import VueObserveVisibility from 'vue-observe-visibility'
import { Router } from 'vue-router'

import { iamPredicates } from '@ankor-io/common/auth/client/predicates'
import { sentryVueInit } from '@ankor-io/sentry-conflux/src/sentry'

import App from '@/App.vue'
import { fetchConfig } from '@/config/fetch'
import { createAuthentication } from '@/iam/authentication'
import { AuthenticationContext } from '@/iam/types'
import createRouter from '@/router'
import '@/style.css'
import { growthbookInstance } from '@/utils/growthbook'
import { initializeGtm } from '@/utils/gtm'

const config = await fetchConfig()
const router: Router = createRouter()

/**
 * Track application errors
 */
window.onerror = function (msg, _url, _line, _col, error) {
  //@ts-ignore
  window.heap?.track('applicationError', {
    message: msg,
    stack: error?.stack,
  })
  console.log(error)
}

/**
 * Track application promises rejections
 */
window.addEventListener('unhandledrejection', function (event) {
  //@ts-ignore
  window.heap?.track('applicationError', {
    message: event.reason.message,
    stack: event.reason.stack,
  })
  console.log(event)
})

const app = sentryVueInit(createApp(App), config.SENTRY)

const authenticationContext: AuthenticationContext = await createAuthentication(config.AUTHENTICATION_CLIENT_OPTIONS)

// Install the navigation guards
router.beforeEach(async (to) =>
  iamPredicates(authenticationContext, config.AUTHENTICATION_CLIENT_OPTIONS).beforeEach(to),
)

app.use(router)
app.use(initializeGtm(config, router))
app.use(authenticationContext)
app.use(VueObserveVisibility)

/**
 * Track vue application errors
 */
app.config.errorHandler = (err: any, vm: any, info: any) => {
  //@ts-ignore
  window.heap?.track('applicationError', {
    error: err.message,
    stack: err.stack,
    info: info,
    vm: vm,
  })
  console.log(err)
}

const growthbook = await growthbookInstance.createGrowthbookClient(
  {
    clientKey: config.GROWTHBOOK.clientKey,
    enableDevMode: true,
  },
  authenticationContext,
)

app.use(growthbook)

app.mount('#app')
