import { createRouter as _createRouter, createWebHistory, Router } from 'vue-router'

const createRouter = (): Router => {
  return _createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
      {
        path: '/auth/callback',
        name: 'auth-callback',
        component: import('@/components/LoadingBlock.vue'),
        meta: {
          skipAuthentication: true,
        },
      },
      {
        path: '/register',
        name: 'auth-register',
        components: {
          main: () => import('@/components/LoadingBlock.vue'),
        },
        meta: {
          toRegistration: true,
        },
      },
      {
        path: '/',
        name: 'summary',
        components: {
          header: () => import('@/views/Header.vue'),
          main: () => import('@/views/SummaryView.vue'),
        },
      },
      {
        path: '/send/:contractType',
        name: 'send-envelope',
        components: {
          header: () => import('@/views/Header.vue'),
          main: () => import('@/views/CreateEnvelopeView.vue'),
        },
        props: {
          main: (route) => ({
            contractType: route.params.contractType,
          }),
        },
      },
      {
        path: '/sent/:envelopeId',
        name: 'sent-envelope',
        components: {
          header: () => import('@/views/Header.vue'),
          main: () => import('@/views/SentEnvelopeView.vue'),
        },
        props: {
          main: (route) => ({
            envelopeId: route.params.envelopeId,
          }),
        },
      },
      {
        path: '/unauthorized',
        name: 'unauthorized',
        components: {
          main: () => import('@/views/Unauthorized.vue'),
        },
        meta: {
          skipAuthentication: true,
        },
      },
      {
        path: '/pay/thanks',
        name: 'pay-thanks',
        components: {
          header: () => import('@/views/Header.vue'),
          main: () => import('@/views/PayThanks.vue'),
        },
        meta: {
          skipAuthentication: true,
        },
      },
    ],
  })
}

export default createRouter
