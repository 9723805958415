import { Plugin } from 'vue'

import { SentryConfig } from '@ankor-io/sentry-conflux/src/types'

import { AuthenticationClientOptions } from '@/iam/types'

/**
 * The config type definition
 */

export declare type Config = {
  /**
   * Kinde client related configurations
   */
  AUTHENTICATION_CLIENT_OPTIONS: AuthenticationClientOptions

  /**
   * GROWTHBOOK config for creating a growthbook instance
   * Use the SDK connection since the endpoint has now been deprecated
   */
  GROWTHBOOK: {
    /**
     * Client key used for registration of SDK connection
     */
    clientKey: string
    /**
     * Whether to enable development mode
     */
    enableDevMode: boolean
  }

  /**
   * GTM config required to initialize GTM plugin
   */
  GTM: {
    /**
     * ID for the GTM Container
     */
    id: string
    /**
     * The preview id of the container
     */
    gtm_preview: string
    /**
     * The auth for GTM container
     */
    gtm_auth: string
  }
  /**
   * Sentry tracing settings
   */
  SENTRY: SentryConfig
}

// intersect our main type with a Vue app plugin for using it in the app.
export type AppConfig = Config & Plugin
export const ConfigKey: string = 'config'
