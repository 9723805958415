<script setup lang="ts">
import { computed, onBeforeMount } from 'vue'
import { RouterView, useRoute } from 'vue-router'

import Modal from '@/components/Modal.vue'
import { useModal } from '@/modal/useModal'

const { isOpen } = useModal()
/**
 * Convert the configured component names in the router to a set.
 */
const components = computed(() => {
  const route = useRoute()
  // flatmap out the names of the components needed.
  // pop them into a Set<> for uniqueness.
  return new Set(route.matched.flatMap((c) => (c?.components ? Object.keys(c.components) : [])))
})

onBeforeMount(() => {
  // read from browser setting of dark theme and move to session storage.
  const storageValue = sessionStorage.getItem('prefers-color-scheme')
  if (storageValue && storageValue === 'dark') {
    document.documentElement.classList.add('dark')
  } else if (!storageValue && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    document.documentElement.classList.add('dark')
  }
})
</script>

<template>
  <Modal v-show="isOpen">
    <div id="modal-container"></div>
  </Modal>
  <div class="antialiased bg-gray-50 dark:bg-gray-900 text-gray-900 dark:text-gray-50">
    <!-- Header -->
    <header class="bg-white dark:bg-gray-800 fixed left-0 right-0 top-0 z-20">
      <nav
        v-if="components.has('header')"
        id="page-header"
        class="px-4 py-2.5 border-b border-gray-200 dark:border-gray-700"
      >
        <RouterView name="header" />
      </nav>

      <RouterView name="navbar" v-if="components.has('navbar')" />
    </header>

    <!-- Sidebar -->
    <aside
      v-if="components.has('leftAside')"
      class="fixed top-0 left-0 z-40 w-64 h-screen mt-[3.750rem] transition-transform -translate-x-full bg-white border-r border-gray-200 md:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
      aria-label="Sidenav"
      id="drawer-navigation"
    >
      <RouterView name="leftAside" />
    </aside>

    <!-- Main section -->
    <main
      v-if="components.has('main')"
      id="main-content"
      class="min-h-screen overflow-auto relative w-full flex flex-col mx-auto"
      :class="[
        components.has('navbar') ? 'pt-32 lg:pt-36' : 'pt-[3.750rem]',
        { 'md:ml-64': components.has('leftAside') },
      ]"
    >
      <RouterView name="main" />
    </main>
  </div>
</template>
